import("preline");
import VueGtag from "vue-gtag";

import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import { createRouter, createWebHistory } from "vue-router";

import AboutPage from "@/components/AboutPage.vue";
import AllGamesPage from "@/components/AllGamesPage.vue";
import CommunityCompetitionsPage from "./components/Competitions/CommunityCompetitionsPage.vue";
import CommunityCompetitionPage from "./components/Competitions/CommunityCompetitionPage.vue";
import ForgotPasswordPage from "@/components/Authentication/ForgotPasswordPage.vue";
import GameDetailsPage from "@/components/GameDetailsPage.vue";
import GameReviewsPage from "@/components/GameReviewsPage.vue";
import GameTipsPage from "@/components/GameTipsPage.vue";
import GamePickerPage from "@/components/GamePickerPage.vue";
import GameRecommendationsChoosePage from "@/components/GameRecommendationsChoosePage.vue";
import GameRecommendationsSelfPage from "@/components/GameRecommendationsSelfPage.vue";
import GameRecommendationsFriendsPage from "@/components/GameRecommendationsFriendsPage.vue";
import GameRecommendationsFromFavoriteGames from "./components/GameRecommendationsFromFavoriteGames.vue";
// import JoinPremiumSuccessPage from '@/components/Authentication/JoinPremiumSuccessPage.vue'
import LoginPage from "@/components/Authentication/LoginPage.vue";
import LeaderboardsPage from "@/components/LeaderboardsPage.vue";
// import PremiumPricingPage from '@/components/PremiumPricingPage.vue'
import SocialPage from "@/components/SocialPage.vue";
import StorePage from "@/components/StorePage.vue";
import SupportMePage from "@/components/SupportMePage.vue";
import NewsPage from "@/components/NewsPage.vue";
import NotFoundPage from "@/components/NotFoundPage.vue";
import ProfilePage from "@/components/Authentication/ProfilePage.vue";
import SignUpPage from "@/components/Authentication/SignUpPage.vue";
import SocialProfilePage from "@/components/SocialProfilePage.vue";
import UserDashboardPage from "@/components/UserDashboardPage.vue";

import App from "./App.vue";

import store from "./store";

import "./styles/app.css";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faArrowDown,
  faArrowUp,
  faBinoculars,
  faCopy,
  faCheck,
  faCheckToSlot,
  faCircleUser,
  faEdit,
  faHeart,
  faGamepad,
  faGear,
  faGift,
  faHourglass,
  faHourglassHalf,
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlassPlus,
  faRankingStar,
  faStar,
  faStarOfLife,
  faThLarge,
  faTrophy,
  faTrash,
  faUser,
  faUsers,
  faX,
} from "@fortawesome/free-solid-svg-icons";

import {
  faDiscord,
  faInstagram,
  faPlaystation,
  faReddit,
  faSteam,
  faTiktok,
  faTwitch,
  faTwitter,
  faXbox,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */
library.add(
  faArrowDown,
  faArrowUp,
  faBinoculars,
  faCheck,
  faCheckToSlot,
  faCircleUser,
  faCopy,
  faDiscord,
  faHeart,
  faHourglassHalf,
  faInstagram,
  faEdit,
  faGamepad,
  faGear,
  faGift,
  faHourglass,
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlassPlus,
  faPlaystation,
  faRankingStar,
  faReddit,
  faStar,
  faStarOfLife,
  faSteam,
  faThLarge,
  faTiktok,
  faTrash,
  faTrophy,
  faTwitch,
  faTwitter,
  faUser,
  faUsers,
  faX,
  faXbox,
  faYoutube
);

const VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID =
  process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

const head = createHead();

// Define your routes
const routes = [
  { path: "/", component: GamePickerPage },
  { path: "/about", component: AboutPage },
  { path: "/competitions", component: CommunityCompetitionsPage },
  {
    path: "/competitions/:competitionUrlSlug",
    component: CommunityCompetitionPage,
  },
  { path: "/my-games", component: UserDashboardPage },
  { path: "/login", component: LoginPage },
  { path: "/profile", component: ProfilePage },
  { path: "/signup", component: SignUpPage },
  { path: "/forgot-password", component: ForgotPasswordPage },
  { path: "/recommendations", component: GameRecommendationsChoosePage },
  { path: "/recommendations-for-self", component: GameRecommendationsSelfPage },
  {
    path: "/recommendations-for-friends",
    component: GameRecommendationsFriendsPage,
  },
  {
    path: "/recommendations-from-favorite-games",
    component: GameRecommendationsFromFavoriteGames,
  },
  { path: "/social", component: SocialPage },
  { path: "/store", component: StorePage },
  { path: "/all-games", component: AllGamesPage },
  { path: "/news", component: NewsPage },
  { path: "/social/leaderboards", component: LeaderboardsPage },
  { path: "/social/profiles/:userGamerTag", component: SocialProfilePage },
  {
    path: "/game/:gameTitle/:gameId",
    props: true,
    children: [
      {
        path: "details", // /game/:gameTitle/:gameId/details
        name: "GameDetailsPage",
        component: GameDetailsPage,
        props: true,
      },
      {
        path: "reviews", // /game/:gameTitle/:gameId/reviews
        name: "GameReviewsPage",
        component: GameReviewsPage,
        props: true,
      },
      {
        path: "tips", // /game/:gameTitle/:gameId/tips
        name: "GameTipsPage",
        component: GameTipsPage,
        props: true,
      },
    ],
  },
  {
    path: "/blog/top-rpgs-gamepass-2025",
    component: () => import("@/components/Blog/BlogPage.vue"),
    props: true,
  },
  { path: "/buy-me-a-coffee", component: SupportMePage },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFoundPage },
  // TODO: To reintroduce once we bring back premium
  // { path: '/join-premium-success', component: JoinPremiumSuccessPage },
  // { path: '/premium-pricing', component: PremiumPricingPage },
];

// Create the router instance and pass the routes option
const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

createApp(App)
  .use(VueGtag, {
    config: { id: VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID },
  })
  .use(router) // Add this line to integrate vue-router
  .use(store)
  .use(head)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
