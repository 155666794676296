<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />

      <div>
        <HeroComponent />
      </div>

      <div class="w-full max-w-md mx-auto pt-0">
        <div class="mt-7 border rounded-xl shadow-sm bg-gray-800 border-gray-700">
          <div class="p-4 sm:p-7">
            <div class="text-center">
              <h1 class="block text-2xl font-bold text-white">Sign up</h1>
              <p class="mt-2 text-sm text-gray-400">
                Already have an account?
                <a class="text-primary decoration-2 hover:underline font-medium cursor-pointer" @click="navigateToLoginPage">
                  Sign in here
                </a>
              </p>
            </div>

            <div class="mt-5">
              <!-- Form -->
              <form @submit.prevent="submitSignUp">
                <div class="grid gap-y-4">
                  <!-- Form Group -->
                  <div>
                    <label for="email" class="block text-sm mb-2 text-white">Email address</label>
                    <div class="relative">
                      <input v-model="email" type="email" id="email" name="email" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-700 border-gray-700 text-gray-400" required aria-describedby="email-error">
                      <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                        <svg class="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                      </div>
                    </div>
                    <p class="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we can get back to you</p>
                  </div>
                  <!-- End Form Group -->

                  <!-- Form Group -->
                  <div>
                    <!-- <label for="xbox-gamertag" class="block text-sm mb-2 text-white">Xbox Gamer Tag (optional)</label> -->
                    <label for="xbox-gamertag" class="block text-sm mb-2 text-white">
                        Xbox Gamer Tag (optional)
                        <div class="hs-tooltip inline-block text-white text-xs">
                          <button @mouseover="showTooltip" @mouseout="hideTooltip" type="button" class="hs-tooltip-toggle">
                            <svg class="inline w-4 h-4 mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            <span ref="tooltipContent" class="hs-tooltip-content opacity-0 transition-opacity inline-block absolute invisible z-10 w-40 py-1 px-2 text-left text-xs font-bold bg-secondary border-green-500" style="border: 1px solid green; background-color: #1a202c; color: white; border-radius: 4px;" role="tooltip">
                              Used for enhanced game recommendations and social features.
                            </span>
                          </button>
                        </div>
                    </label>
                    <div class="relative">
                      <input v-model="xboxGamertag" type="text" id="xbox-gamertag" name="xbox-gamertag" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-700 border-gray-700 text-gray-400">
                      <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                        <svg class="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <!-- End Form Group -->

                  <!-- Form Group -->
                  <div>
                    <label for="password" class="block text-sm mb-2 text-white">Password</label>
                    <div class="relative">
                      <input v-model="password" type="password" id="password" name="password" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-700 border-gray-700 text-gray-400" required aria-describedby="password-error">
                      <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                        <svg class="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                      </div>
                    </div>
                    <p class="hidden text-xs text-red-600 mt-2" id="password-error">8+ characters required</p>
                  </div>
                  <!-- End Form Group -->

                  <!-- Form Group -->
                  <div>
                    <label for="confirm-password" class="block text-sm mb-2 text-white">Confirm Password</label>
                    <div class="relative">
                      <input v-model="confirmPassword" type="password" id="confirm-password" name="confirm-password" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-700 border-gray-700 text-gray-400" required aria-describedby="confirm-password-error">
                      <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                        <svg class="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                      </div>
                    </div>
                    <p class="hidden text-xs text-red-600 mt-2" id="confirm-password-error">Password does not match the password</p>
                  </div>
                  <!-- End Form Group -->

                  <!-- Checkbox -->
                  <div class="flex items-center">
                    <div class="flex">
                      <input id="remember-me" name="remember-me" type="checkbox" class="shrink-0 mt-0.5 rounded text-blue-600 focus:ring-blue-500 bg-gray-800 border-gray-700 checked:bg-blue-500 checked:border-blue-500 focus:ring-offset-gray-800" v-model="termsOfServiceAccepted">
                    </div>
                    <div class="ml-3">
                      <label for="remember-me" class="text-sm text-white">I accept the <a class="text-primary decoration-2 hover:underline font-medium cursor-pointer" target="_blank" rel="noreferrer" href="https://www.termsfeed.com/live/bf697839-a305-47ce-9e9e-ccdc429b8a5b">Terms and Conditions</a></label>
                    </div>
                  </div>
                  <!-- End Checkbox -->

                  <button type="submit" class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-primary text-white bg-primary:hover focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm focus:ring-offset-gray-800">Sign up</button>
                  <div v-if="signUpError" class="max-w-xs border rounded-md shadow-lg bg-gray-800 border-gray-700" role="alert">
                    <div class="flex p-4">
                      <div class="flex-shrink-0">
                        <svg class="h-4 w-4 text-red-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                        </svg>
                      </div>
                      <div class="ml-3">
                        <p class="text-sm text-gray-400">
                         {{ signUpErrorDetail }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <!-- End Form -->
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  </body>
</template>

<script>
import { useHead } from '@vueuse/head';

import FooterComponent from '@/components/Layout/FooterComponent.vue';
import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
import HeroComponent from '@/components/Layout/HeroComponent.vue';

useHead({
  title: 'Sign Up for GamePass Picker - Discover the Best GamePass Games and More!',
  meta: [
    { 
      name: 'description', 
      content: 'Sign up for GamePass Picker to discover the best GamePass games, review games, and use our game finder to get personalized game recommendations. With features like a game randomizer, updates on games leaving GamePass, random GamePass game selection, and game avatars, GamePass Picker offers everything you need for an enhanced gaming experience. Join the game community to find gaming friends, join gaming competitions, and enjoy gaming with friends across GamePass Core, GamePass PC, and GamePass Ultimate games.' 
    },
    { 
      name: 'keywords', 
      content: 'best GamePass games, review games, game finder, game recommendations, game randomizer, games leaving GamePass, random GamePass game, game avatars, game suggestions, random game selector, find gaming friends, game community, gaming competitions, gaming with friends, GamePass new games, GamePass Core games, GamePass PC games, GamePass Ultimate games' 
    },
    { 
      property: 'og:title', 
      content: 'Sign Up for GamePass Picker - Unlock Top GamePass Features and Community' 
    },
    { 
      property: 'og:description', 
      content: 'Discover and sign up for GamePass Picker to access the best GamePass games, game reviews, a game finder, personalized game recommendations, and a thriving game community. Join now to explore top features, play with friends, and enjoy GamePass Core, PC, and Ultimate games!' 
    },
  ],
});


export default {
  data() {
    return {
      message: 'Hello from my Vue component!',
      email: '',
      xboxGamertag: null,
      password: '',
      confirmPassword: '',
      signUpError: false,
      signUpErrorDetail: 'Oops, something went wrong with the sign-up process. Please try again.',
      termsOfServiceAccepted: false,
      xbgtTooltipVisible: false
    };
  },
  components: {
    FooterComponent,
    HeaderComponent,
    HeroComponent,
  },
  beforeMount: function() {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  },
  methods: {
    toggleXbgtTooltip: function () {
      this.xbgtTooltipVisible = !this.xbgtTooltipVisible;
    },
    navigateToLoginPage: function () {
      this.$router.push({ path: '/login', query: { email: this.email } });
    },
    showTooltip(event) {
      const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-0', 'invisible');
      tooltip.classList.add('opacity-100', 'visible');
    },
    hideTooltip(event) {
      const tooltip = event.currentTarget.querySelector('.hs-tooltip-content');
      tooltip.classList.remove('opacity-100', 'visible');
      tooltip.classList.add('opacity-0', 'invisible');
    },
    submitSignUp: async function () {
      // Scrolls to the bottom in case there's an error to see
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);

      this.signUpError = false;

      if (!this.termsOfServiceAccepted) {
        this.signUpErrorDetail = 'Please read and accept the terms of service first, then try again.'
        this.signUpError = true;
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.signUpErrorDetail = 'Your password and confirmation password do not match, please try again.'
        this.signUpError = true;
        return;
      }

      try {

        // Get the current URL's query string
        const urlParams = new URLSearchParams(window.location.search);

        // Extract the friend_referral value if it exists
        const friendReferral = urlParams.get('friend_referral');

        // Prepare the body object
        const requestBody = {
          email: this.email,
          password: this.password,
          xbox_gamertag: this.xboxGamertag,
        };

        // Add friend_referral to the body if it exists
        if (friendReferral) {
          requestBody.friend_referral = friendReferral;
        } else {
          requestBody.friend_referral = null;
        }

        // Make the fetch request
        const registerResult = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        // Check if the result returns a 201 "created" status code
        if (registerResult.status !== 201) {
          // Request was not successful
          this.signUpErrorDetail = 'Oops, something went wrong with the sign-up process or an account with this e-mail may already exist. Please try again or contact support.'
          this.signUpError = true;
          return;
        } else {
          // Request was successful, so we log the user in
          await this.submitLogin();
        }

      } catch (e) {
        if (e.message === 'Network Error' || e.message === 'Failed to fetch') {
          // The server is down
          this.signUpErrorDetail = 'Our servers are down right now as Mario is taking a shower. Please try again later or contact support.'
        } else {
          this.signUpErrorDetail = 'Oops, something went wrong with the sign-up process or an account with this e-mail may already exist. Please try again or contact support.'
        }
        this.signUpError = true;
        return;
      }
    },
    submitLogin: async function () {
      try {
        const result = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',  // Necessary for passing of login cookies from backend
          body: JSON.stringify({
            email: this.email,
            password: this.password
          })
        });

        const data = await result.json();
        
        if (result.status === 200) {
          // Assuming the token is returned in a field named 'token' in the JSON response
          const token = data.jwt;
          localStorage.setItem('authToken', token);

          this.$router.push('/my-games');
        
        } else {
          this.navigateToLoginPage();
        }

      } catch (e) {
        this.navigateToLoginPage();
        return;
      }
    }

  }
}
</script>

<style scoped>


/* Tooltip button styling */
.tooltip button {
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Basic styling for the tooltip content */
.tooltip-content {
  display: none; /* Initially hidden, shown by Vue.js */
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 10;
  bottom: 100%; /* Position above the button */
  left: 50%; /* Start at the middle of the button */
  transform: translateX(-50%); /* Center align tooltip */
  margin-bottom: 5px; /* Distance from the button */
  transition: opacity 0.3s ease-in-out;
}

.hs-tooltip-content.opacity-0 {
  opacity: 0;
  visibility: hidden;
}
.hs-tooltip-content.opacity-100 {
  opacity: 1;
  visibility: visible;
}

/* Tooltip content arrow (optional) */
.tooltip-content::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; /* Arrow color matches tooltip background */
}


</style>
