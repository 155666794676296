<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
      <div v-if="!loading && authenticated" class="h-[84vh] max-h-[84vh] overflow-y-auto box-border scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
        <div>
          <div class="text-left px-3">
            Welcome to your gaming dashboard. Add games from the 
            <a class="text-primary decoration-2 hover:underline font-medium cursor-pointer" @click="navigateToGamePickerPage">Game Picker</a>, 
            <a class="text-primary decoration-2 hover:underline font-medium cursor-pointer" @click="navigateToGameSearchPage">Search Games</a>
            and 
            <a class="text-primary decoration-2 hover:underline font-medium cursor-pointer" @click="navigateToRecommendationsPage">Recommendations</a>
            pages. Move around games to the lists below by looking for the following symbols 
            (<font-awesome-icon class="text-white px-1" icon="fa-solid fa-gift" /> - interested, 
            <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gamepad" /> - playing, 
            <font-awesome-icon class="text-white px-1" icon="fa-solid fa-hourglass-half" /> - played, 
            <font-awesome-icon class="text-white px-1" icon="fa-solid fa-check-to-slot" /> - completed,
            <font-awesome-icon class="text-white px-1" icon="fa-solid fa-x" /> - remove)
            and selecting them. 
          </div>
          <div v-if="isLargeScreen" class="mt-2 pl-3 text-left">
            <button class="text-left font-bold mr-3 disabled cursor-auto">View</button>
            <button @click="sectionShowing = 'All'" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-left py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-l-lg border bg-secondary text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'All' ? 'bg-primary' : 'bg-secondary']">
              All
            </button>
            <button @click="sectionShowing = 'Interested'" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-middle py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'Interested' ? 'bg-primary' : 'bg-secondary']">
              Interested
            </button>
            <button @click="sectionShowing = 'Playing'" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-middle py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'Playing' ? 'bg-primary' : 'bg-secondary']">
              Playing
            </button>
            <button @click="sectionShowing = 'Played'" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-middle py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'Played' ? 'bg-primary' : 'bg-secondary']">
              Played
            </button>
            <button @click="sectionShowing = 'Completed'" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-right py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-r-lg border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'Completed' ? 'bg-primary' : 'bg-secondary']">
              Completed
            </button>
          </div>
          <div class="mt-2 pl-3 text-left" v-else>
            <button class="text-left font-bold mr-3 disabled cursor-auto">View</button>
            <button @click="sectionShowing = 'All'" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-left py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-l-lg border bg-secondary text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'All' ? 'bg-primary' : 'bg-secondary']">
              <font-awesome-icon class="text-white px-1 cursor-pointer" icon="fa-solid fa-th-large" />
            </button>
            <button @click="sectionShowing = 'Interested'" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-middle py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'Interested' ? 'bg-primary' : 'bg-secondary']">
              <font-awesome-icon class="text-white px-1 cursor-pointer" icon="fa-solid fa-gift" />
            </button>
            <button @click="sectionShowing = 'Playing'" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-middle py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'Playing' ? 'bg-primary' : 'bg-secondary']">
              <font-awesome-icon class="text-white px-1 cursor-pointer" icon="fa-solid fa-gamepad" />
            </button>
            <button @click="sectionShowing = 'Played'" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-middle py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'Played' ? 'bg-primary' : 'bg-secondary']">
              <font-awesome-icon class="text-white px-1 cursor-pointer" icon="fa-solid fa-hourglass-half" />
            </button>
            <button @click="sectionShowing = 'Completed'" 
                    type="button" 
                    :class="[
                      'game-list-btn-selector game-list-btn-selector-right py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-r-lg border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none',
                      sectionShowing === 'Completed' ? 'bg-primary' : 'bg-secondary']">
              <font-awesome-icon class="text-white px-1 cursor-pointer" icon="fa-solid fa-check-to-slot" />
            </button>
          </div>
        </div>

        <div v-if="sectionShowing == 'All' || sectionShowing == 'Interested'">
          <hr class="my-4">
          <div class="text-xl font-bold pb-3">Interested</div>

          <div v-if="wishlistGames.length > 0" class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 mx-4 gap-6 place-items-center">
            <div 
              v-for="(game, index) in wishlistGames" 
              :key="index.toString() + Math.random().toString(36).slice(2, 11)" 
              @click="viewGameDetailsPage(game.xbox_api_id, game.title, $event)"
              class="w-32 relative flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark hover:border-1 hover:border-[#42b983] hover:shadow-xl hover:shadow-[#42b983]/50 transition-all duration-200">
              <div v-if="initialLoad" class="relative">
                <img class="w-full h-2/5 rounded-t-xl object-cover border-b border-gray-700" :src="game.image_uris[0]" alt="The banner image for the game">
                <div @click.stop.prevent="addGameInterest('dropped', game.title)" class="absolute top-0 right-0 m-2 cursor-pointer text-white hover:text-green-300">
                  <font-awesome-icon icon="fa-solid fa-x" />
                </div>
              </div>
              <div v-else class="relative">
                <img class="w-full h-2/5 rounded-t-xl object-cover border-b border-gray-700" :src="game.image_loaded ? game.image_uris[0] : loadingImagePlaceholderPath" @load="game.image_loaded = true" alt="The banner image for the game">
                <div @click.stop.prevent="addGameInterest('dropped', game.title)" class="absolute top-0 right-0 m-2 cursor-pointer text-white hover:text-green-300">
                  <font-awesome-icon icon="fa-solid fa-x" />
                </div>
              </div>
              <div class="py-2 max-h-32 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                  <h3 class="text-xs font-bold text-white pt-2">{{ game.title }}</h3>
              </div>
              <div v-if="game.leaving_soon" class="text-xs text-primary font-bold underline pb-1">
                Leaving {{ game.leaving_soon_date }}
              </div>
              <div v-if="isRecentlyReleased(game)" class="text-xs text-primary font-bold underline pb-1">
                Just Released
              </div>
              <div v-if="game.left_gamepass" class="text-xs text-primary font-bold underline pb-1">
                Left Game Pass
              </div>
              <hr class="mb-1" />
              <div class="flex items-center justify-between text-white text-left px-1">
                <div @click.stop="navigateToMetacriticUrl(game)" :class="['cursor-pointer', 'score-box', game.metacritic_score_color_class]">
                  <span class="score">{{ game.metacritic_score }}</span>
                </div>
                <div @click.stop="navigateToIgnUrl(game)" class="flex items-center cursor-pointer mt-1 ign-score-container text-xs">
                  <img class="w-8 h-8 align-center" src="../assets/ign-score-background.png" alt="IGN score logo which is a red hexagon">
                  <div class="ign-score">{{ game.ign_score }}</div>
                </div>
                <div @click.stop="navigateToHowLongToBeatUrl(game)" class="cursor-pointer">
                  <img class="w-8 h-8" src="../assets/howlongtobeat.png" alt="How long to beat logo">
                </div>
              </div>
              <hr class="mt-1" />
              <div class="px-1 flex justify-center text-center text-xs py-2 flex-col">
                <span class="text-center">{{ game.ign_all_possible_genres.join(', ') }}</span>
              </div>
              <hr />
              <div class="px-1 flex justify-between text-white text-xs text-left py-2">
                <span v-if="game.ign_duration_hours">Game length: {{ game.ign_duration_hours }}</span>
                <span v-else>Game length: --</span>
              </div>
              <hr />
              <div class="px-1 flex justify-center text-white text-xs text-center py-2">
                <span>
                  Released in 
                  <span v-if="game.original_release_year">{{ game.original_release_year }}</span>
                  <span v-else>--</span>
                </span>
              </div>
              <hr />
              <div class="px-1 flex text-primary justify-center text-center text-xs py-2 flex-col">
                <div>
                  <a @click.stop class="hover:underline" :href="game.microsoft_store_url" target="_blank">
                    Save to <font-awesome-icon 
                    class="text-white px-1 hover:text-green-300 cursor-pointer" 
                    :icon="['fab', 'xbox']" 
                  /></a>
                </div>
                <div>
                  <a @click="selectGame(game)" data-hs-overlay="#hs-gameprops-modal-allgames" class="cursor-pointer hover:underline" target="_blank">Game Properties</a>
                </div>
                <div>
                  <a @click.stop class="hover:underline" :href="game.youtube_review_url" target="_blank">Review Video</a>
                </div>
                <div>
                  <a @click.stop class="hover:underline" :href="game.youtube_trailer_url" target="_blank">Trailer Video</a>
                </div>
                <div>
                  <a @click.stop class="hover:underline" :href="game.youtube_walkthrough_url" target="_blank">Walkthrough Video</a>
                </div>
              </div>
              <hr />
              <div class="my-2">
                <font-awesome-icon @click.stop.prevent="addGameInterest('wishlist', game.title)" class="text-white px-1 text-primary" icon="fa-solid fa-gift" />
                <font-awesome-icon @click.stop.prevent="addGameInterest('currently playing', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-gamepad" />
                <font-awesome-icon @click.stop.prevent="addGameInterest('played', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-hourglass-half" />
                <font-awesome-icon @click.stop.prevent="addGameInterest('completed', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-check-to-slot" />
              </div>
            </div>
          </div>
          <div v-else class="text-xs px-3 font-bold text-white text-left">
            You don't have any games in your wishlist. Make a wish, then add some.
            <button @click="navigateToGameSearchPage()" 
                    type="button" 
                    class='add-more-games-btn mx-2 sm:mt-0 mt-2 py-1 px-2 inline-flex items-center text-sm font-medium rounded-r-lg border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none'>
              +
            </button>
          </div>
        </div>

        <div v-if="sectionShowing == 'All' || sectionShowing == 'Playing'">
          <hr class="my-4">
          <div>
            <div class="text-xl font-bold pb-3">Currently Playing</div>
            
                <div v-if="currentlyPlayingGames.length > 0" class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 mx-4 gap-6 place-items-center">
                  <div v-for="(game, index) in currentlyPlayingGames" 
                       :key="index.toString() + Math.random().toString(36).slice(2, 11)" 
                       @click="viewGameDetailsPage(game.xbox_api_id, game.title, $event)"
                       class="w-32 relative flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark hover:border-1 hover:border-[#42b983] hover:shadow-xl hover:shadow-[#42b983]/50 transition-all duration-200"
                      >    
                  <div v-if="initialLoad" class="relative">
                    <img class="w-full h-2/5 rounded-t-xl object-cover border-b border-gray-700" :src="game.image_uris[0]" alt="The banner image for the game">
                    <div @click.stop.prevent="addGameInterest('dropped', game.title)" class="absolute top-0 right-0 m-2 cursor-pointer text-white hover:text-green-300">
                      <font-awesome-icon icon="fa-solid fa-x" />
                    </div>
                  </div>
                  <div v-else class="relative">
                    <img class="w-full h-2/5 rounded-t-xl object-cover border-b border-gray-700" :src="game.image_loaded ? game.image_uris[0] : loadingImagePlaceholderPath" @load="game.image_loaded = true" alt="The banner image for the game">
                    <div @click.stop.prevent="addGameInterest('dropped', game.title)" class="absolute top-0 right-0 m-2 cursor-pointer text-white hover:text-green-300">
                      <font-awesome-icon icon="fa-solid fa-x" />
                    </div>
                  </div>

                  <div class="py-2 max-h-32 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                      <h3 class="text-xs font-bold text-white pt-2">{{ game.title }}</h3>
                  </div>
                  <div v-if="game.leaving_soon" class="text-xs text-primary font-bold underline pb-1">
                    Leaving {{ game.leaving_soon_date }}
                  </div>
                  <div v-if="isRecentlyReleased(game)" class="text-xs text-primary font-bold underline pb-1">
                    Just Released
                  </div>
                  <div v-if="game.left_gamepass" class="text-xs text-primary font-bold underline pb-1">
                    Left Game Pass
                  </div>
                  <hr class="mb-1" />
                  <div class="flex items-center justify-between text-white text-left px-1">
                    <div @click.stop="navigateToMetacriticUrl(game)" :class="['cursor-pointer', 'score-box', game.metacritic_score_color_class]">
                      <span class="score">{{ game.metacritic_score }}</span>
                    </div>
                    <div @click.stop="navigateToIgnUrl(game)" class="flex items-center cursor-pointer mt-1 ign-score-container text-xs">
                      <img class="w-8 h-8 align-center" src="../assets/ign-score-background.png" alt="IGN score logo which is a red hexagon">
                      <div class="ign-score">{{ game.ign_score }}</div>
                    </div>
                    <div @click.stop="navigateToHowLongToBeatUrl(game)" class="cursor-pointer">
                      <img class="w-8 h-8" src="../assets/howlongtobeat.png" alt="How long to beat logo">
                    </div>
                  </div>
                  <hr class="mt-1" />
                  <div class="px-1 flex justify-center text-center text-xs py-2 flex-col">
                    <span class="text-center">{{ game.ign_all_possible_genres.join(', ') }}</span>
                  </div>
                  <hr />
                  <div class="px-1 flex justify-between text-white text-xs text-left py-2">
                    <span v-if="game.ign_duration_hours">Game length: {{ game.ign_duration_hours }}</span>
                    <span v-else>Game length: --</span>
                  </div>
                  <hr />
                  <div class="px-1 flex justify-center text-white text-xs text-center py-2">
                    <span>
                      Released in 
                      <span v-if="game.original_release_year">{{ game.original_release_year }}</span>
                      <span v-else>--</span>
                    </span>
                  </div>
                  <hr />
                  <div class="px-1 flex text-primary justify-center text-center text-xs py-2 flex-col">
                    <div>
                      <a @click.stop class="hover:underline" :href="game.microsoft_store_url" target="_blank">
                        Save to <font-awesome-icon 
                        class="text-white px-1 hover:text-green-300 cursor-pointer" 
                        :icon="['fab', 'xbox']" 
                      /></a>
                    </div>
                    <div>
                      <a @click="selectGame(game)" data-hs-overlay="#hs-gameprops-modal-allgames" class="cursor-pointer hover:underline" target="_blank">Game Properties</a>
                    </div>
                    <div>
                      <a @click.stop class="hover:underline" :href="game.youtube_review_url" target="_blank">Review Video</a>
                    </div>
                    <div>
                      <a @click.stop class="hover:underline" :href="game.youtube_trailer_url" target="_blank">Trailer Video</a>
                    </div>
                    <div>
                      <a @click.stop class="hover:underline" :href="game.youtube_walkthrough_url" target="_blank">Walkthrough Video</a>
                    </div>
                  </div>

                  <hr />

                  <div class="scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                      <div class="my-2">
                        <span class="rate">
                          <i @click.stop="rateGame(game.title, 1)" :class="game.game_rating > 0 ? 'starred' : ''">★</i>
                          <i @click.stop="rateGame(game.title, 2)" :class="game.game_rating > 1 ? 'starred' : ''">★</i>
                          <i @click.stop="rateGame(game.title, 3)" :class="game.game_rating > 2 ? 'starred' : ''">★</i>
                          <i @click.stop="rateGame(game.title, 4)" :class="game.game_rating > 3 ? 'starred' : ''">★</i>
                          <i @click.stop="rateGame(game.title, 5)" :class="game.game_rating > 4 ? 'starred' : ''">★</i>
                        </span>
                      </div>
                  </div>

                  <hr />

                  <div class="my-2">
                    <font-awesome-icon @click.stop.prevent="addGameInterest('wishlist', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-gift" />
                    <font-awesome-icon @click.stop.prevent="addGameInterest('currently playing', game.title)" class="text-white px-1 text-primary" icon="fa-solid fa-gamepad" />
                    <font-awesome-icon @click.stop.prevent="addGameInterest('played', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-hourglass-half" />
                    <font-awesome-icon @click.stop.prevent="addGameInterest('completed', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-check-to-slot" />
                  </div>

              </div>
            </div>
            <div v-else class="text-xs px-3 font-bold text-white text-left">
              You have not started playing any games yet, start playing some and add them to this list.
              <button @click="navigateToGameSearchPage()" 
                      type="button" 
                      class='add-more-games-btn mx-2 sm:mt-0 mt-2 py-1 px-2 inline-flex items-center text-sm font-medium rounded-r-lg border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none'>
                +
              </button>
            </div>
          </div>
          <div v-if="currentlyPlayingGames.length > 0" class="text-center pt-3 px-3">
            Don't forget to <span class="font-extrabold">RATE</span> the games you play - it helps us generate AI-based game <a class="text-primary decoration-2 hover:underline font-medium cursor-pointer" @click="navigateToRecommendationsPage">Recommendations</a> for you and also reward you with <span class="text-yellow-400 font-bold">GameKarma</span> points.
          </div>
        </div>

        <div v-if="sectionShowing == 'All' || sectionShowing == 'Played'">
          <hr class="my-4">
          <div>
            <div class="text-xl font-bold pb-3">Played</div>
            
              <div v-if="playedGames.length > 0" class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 mx-4 gap-6 place-items-center">
                <div v-for="(game, index) in playedGames" 
                     :key="index.toString() + Math.random().toString(36).slice(2, 11)" 
                     @click="viewGameDetailsPage(game.xbox_api_id, game.title, $event)"
                     class="w-32 relative flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark hover:border-1 hover:border-[#42b983] hover:shadow-xl hover:shadow-[#42b983]/50 transition-all duration-200"
                    >    

                  <div v-if="initialLoad" class="relative">
                    <img class="w-full h-2/5 rounded-t-xl object-cover border-b border-gray-700" :src="game.image_uris[0]" alt="The banner image for the game">
                    <div @click.stop.prevent="addGameInterest('dropped', game.title)" class="absolute top-0 right-0 m-2 cursor-pointer text-white hover:text-green-300">
                      <font-awesome-icon icon="fa-solid fa-x" />
                    </div>
                  </div>
                  <div v-else class="relative">
                    <img class="w-full h-2/5 rounded-t-xl object-cover border-b border-gray-700" :src="game.image_loaded ? game.image_uris[0] : loadingImagePlaceholderPath" @load="game.image_loaded = true" alt="The banner image for the game">
                    <div @click.stop.prevent="addGameInterest('dropped', game.title)" class="absolute top-0 right-0 m-2 cursor-pointer text-white hover:text-green-300">
                      <font-awesome-icon icon="fa-solid fa-x" />
                    </div>
                  </div>

                  <div class="py-2 max-h-32 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                      <h3 class="text-xs font-bold text-white pt-2">{{ game.title }}</h3>
                  </div>
                  <div v-if="game.leaving_soon" class="text-xs text-primary font-bold underline pb-1">
                    Leaving {{ game.leaving_soon_date }}
                  </div>
                  <div v-if="isRecentlyReleased(game)" class="text-xs text-primary font-bold underline pb-1">
                    Just Released
                  </div>
                  <div v-if="game.left_gamepass" class="text-xs text-primary font-bold underline pb-1">
                    Left Game Pass
                  </div>
                  <hr class="mb-1" />
                  <div class="flex items-center justify-between text-white text-left px-1">
                    <div @click.stop="navigateToMetacriticUrl(game)" :class="['cursor-pointer', 'score-box', game.metacritic_score_color_class]">
                      <span class="score">{{ game.metacritic_score }}</span>
                    </div>
                    <div @click.stop="navigateToIgnUrl(game)" class="flex items-center cursor-pointer mt-1 ign-score-container text-xs">
                      <img class="w-8 h-8 align-center" src="../assets/ign-score-background.png" alt="IGN score logo which is a red hexagon">
                      <div class="ign-score">{{ game.ign_score }}</div>
                    </div>
                    <div @click.stop="navigateToHowLongToBeatUrl(game)" class="cursor-pointer">
                      <img class="w-8 h-8" src="../assets/howlongtobeat.png" alt="How long to beat logo">
                    </div>
                  </div>
                  <hr class="mt-1" />
                  <div class="px-1 flex justify-center text-center text-xs py-2 flex-col">
                    <span class="text-center">{{ game.ign_all_possible_genres.join(', ') }}</span>
                  </div>
                  <hr />
                  <div class="px-1 flex justify-between text-white text-xs text-left py-2">
                    <span v-if="game.ign_duration_hours">Game length: {{ game.ign_duration_hours }}</span>
                    <span v-else>Game length: --</span>
                  </div>
                  <hr />
                  <div class="px-1 flex justify-center text-white text-xs text-center py-2">
                    <span>
                      Released in 
                      <span v-if="game.original_release_year">{{ game.original_release_year }}</span>
                      <span v-else>--</span>
                    </span>
                  </div>
                  <hr />
                  <div class="px-1 flex text-primary justify-center text-center text-xs py-2 flex-col">
                    <div>
                      <a @click.stop class="hover:underline" :href="game.microsoft_store_url" target="_blank">
                        Save to <font-awesome-icon 
                        class="text-white px-1 hover:text-green-300 cursor-pointer" 
                        :icon="['fab', 'xbox']" 
                      /></a>
                    </div>
                    <div>
                      <a @click="selectGame(game)" data-hs-overlay="#hs-gameprops-modal-allgames" class="cursor-pointer hover:underline" target="_blank">Game Properties</a>
                    </div>
                    <div>
                      <a @click.stop class="hover:underline" :href="game.youtube_review_url" target="_blank">Review Video</a>
                    </div>
                    <div>
                      <a @click.stop class="hover:underline" :href="game.youtube_trailer_url" target="_blank">Trailer Video</a>
                    </div>
                    <div>
                      <a @click.stop class="hover:underline" :href="game.youtube_walkthrough_url" target="_blank">Walkthrough Video</a>
                    </div>
                  </div>

                  <hr />

                  <div class="scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                      <div class="my-2">
                        <span class="rate">
                          <i @click.stop="rateGame(game.title, 1)" :class="game.game_rating > 0 ? 'starred' : ''">★</i>
                          <i @click.stop="rateGame(game.title, 2)" :class="game.game_rating > 1 ? 'starred' : ''">★</i>
                          <i @click.stop="rateGame(game.title, 3)" :class="game.game_rating > 2 ? 'starred' : ''">★</i>
                          <i @click.stop="rateGame(game.title, 4)" :class="game.game_rating > 3 ? 'starred' : ''">★</i>
                          <i @click.stop="rateGame(game.title, 5)" :class="game.game_rating > 4 ? 'starred' : ''">★</i>
                        </span>
                      </div>
                  </div>

                  <hr />

                  <div class="my-2">
                    <font-awesome-icon @click.stop.prevent="addGameInterest('wishlist', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-gift" />
                    <font-awesome-icon @click.stop.prevent="addGameInterest('currently playing', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-gamepad" />
                    <font-awesome-icon @click.stop.prevent="addGameInterest('played', game.title)" class="text-white px-1 text-primary" icon="fa-solid fa-hourglass-half" />
                    <font-awesome-icon @click.stop.prevent="addGameInterest('completed', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-check-to-slot" />
                  </div>
              </div>
            </div>
            <div v-else class="text-xs px-3 font-bold text-white text-left">
              You haven't played any games yet. What are you waiting for? They aren't going to play themselves.
              <button @click="navigateToGameSearchPage()" 
                      type="button" 
                      class='add-more-games-btn mx-2 sm:mt-0 mt-2 py-1 px-2 inline-flex items-center text-sm font-medium rounded-r-lg border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none'>
                +
              </button>
            </div>
            <div v-if="playedGames.length > 0" class="text-center pt-3 px-3">
              Don't forget to <span class="font-extrabold">RATE</span> the games you play - it helps us generate AI-based game <a class="text-primary decoration-2 hover:underline font-medium cursor-pointer" @click="navigateToRecommendationsPage">Recommendations</a> for you and also reward you with <span class="text-yellow-400 font-bold">GameKarma</span> points.
            </div>
          </div>
        </div>

        <div v-if="sectionShowing == 'All' || sectionShowing == 'Completed'">
          <hr class="my-4">
          <div class="text-xl font-bold pb-3">Completed</div>
          
            <div v-if="completedGames.length > 0" class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 mx-4 gap-6 place-items-center">
              <div v-for="(game, index) in completedGames" 
                   :key="index.toString() + Math.random().toString(36).slice(2, 11)" 
                   @click="viewGameDetailsPage(game.xbox_api_id, game.title, $event)"
                   class="w-32 relative flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark hover:border-1 hover:border-[#42b983] hover:shadow-xl hover:shadow-[#42b983]/50 transition-all duration-200"
                  >    

                <div v-if="initialLoad" class="relative">
                  <img class="w-full h-2/5 rounded-t-xl object-cover border-b border-gray-700" :src="game.image_uris[0]" alt="The banner image for the game">
                  <div @click.stop.prevent="addGameInterest('dropped', game.title)" class="absolute top-0 right-0 m-2 cursor-pointer text-white hover:text-green-300">
                    <font-awesome-icon icon="fa-solid fa-x" />
                  </div>
                </div>
                <div v-else class="relative">
                  <img class="w-full h-2/5 rounded-t-xl object-cover border-b border-gray-700" :src="game.image_loaded ? game.image_uris[0] : loadingImagePlaceholderPath" @load="game.image_loaded = true" alt="The banner image for the game">
                  <div @click.stop.prevent="addGameInterest('dropped', game.title)" class="absolute top-0 right-0 m-2 cursor-pointer text-white hover:text-green-300">
                    <font-awesome-icon icon="fa-solid fa-x" />
                  </div>
                </div>

                <div class="py-2 max-h-32 overflow-y-auto scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                    <h3 class="text-xs font-bold text-white pt-2">{{ game.title }}</h3>
                </div>
                <div v-if="game.leaving_soon" class="text-xs text-primary font-bold underline pb-1">
                  Leaving {{ game.leaving_soon_date }}
                </div>
                <div v-if="isRecentlyReleased(game)" class="text-xs text-primary font-bold underline pb-1">
                  Just Released
                </div>
                <div v-if="game.left_gamepass" class="text-xs text-primary font-bold underline pb-1">
                  Left Game Pass
                </div>
                <hr class="mb-1" />
                <div class="flex items-center justify-between text-white text-left px-1">
                  <div @click.stop="navigateToMetacriticUrl(game)" :class="['cursor-pointer', 'score-box', game.metacritic_score_color_class]">
                    <span class="score">{{ game.metacritic_score }}</span>
                  </div>
                  <div @click.stop="navigateToIgnUrl(game)" class="flex items-center cursor-pointer mt-1 ign-score-container text-xs">
                    <img class="w-8 h-8 align-center" src="../assets/ign-score-background.png" alt="IGN score logo which is a red hexagon">
                    <div class="ign-score">{{ game.ign_score }}</div>
                  </div>
                  <div @click.stop="navigateToHowLongToBeatUrl(game)" class="cursor-pointer">
                    <img class="w-8 h-8" src="../assets/howlongtobeat.png" alt="How long to beat logo">
                  </div>
                </div>
                <hr class="mt-1" />
                <div class="px-1 flex justify-center text-center text-xs py-2 flex-col">
                  <span class="text-center">{{ game.ign_all_possible_genres.join(', ') }}</span>
                </div>
                <hr />
                <div class="px-1 flex justify-between text-white text-xs text-left py-2">
                  <span v-if="game.ign_duration_hours">Game length: {{ game.ign_duration_hours }}</span>
                  <span v-else>Game length: --</span>
                </div>
                <hr />
                <div class="px-1 flex justify-center text-white text-xs text-center py-2">
                  <span>
                    Released in 
                    <span v-if="game.original_release_year">{{ game.original_release_year }}</span>
                    <span v-else>--</span>
                  </span>
                </div>
                <hr />
                <div class="px-1 flex text-primary justify-center text-center text-xs py-2 flex-col">
                  <div>
                    <a @click.stop class="hover:underline" :href="game.microsoft_store_url" target="_blank">
                      Save to <font-awesome-icon 
                      class="text-white px-1 hover:text-green-300 cursor-pointer" 
                      :icon="['fab', 'xbox']" 
                    /></a>
                  </div>
                  <div>
                    <a @click="selectGame(game)" data-hs-overlay="#hs-gameprops-modal-allgames" class="cursor-pointer hover:underline" target="_blank">Game Properties</a>
                  </div>
                  <div>
                    <a @click.stop class="hover:underline" :href="game.youtube_review_url" target="_blank">Review Video</a>
                  </div>
                  <div>
                    <a @click.stop class="hover:underline" :href="game.youtube_trailer_url" target="_blank">Trailer Video</a>
                  </div>
                  <div>
                    <a @click.stop class="hover:underline" :href="game.youtube_walkthrough_url" target="_blank">Walkthrough Video</a>
                  </div>
                </div>

                <hr />

                <div class="scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                    <div class="my-2">
                      <span class="rate">
                        <i @click.stop="rateGame(game.title, 1)" :class="game.game_rating > 0 ? 'starred' : ''">★</i>
                        <i @click.stop="rateGame(game.title, 2)" :class="game.game_rating > 1 ? 'starred' : ''">★</i>
                        <i @click.stop="rateGame(game.title, 3)" :class="game.game_rating > 2 ? 'starred' : ''">★</i>
                        <i @click.stop="rateGame(game.title, 4)" :class="game.game_rating > 3 ? 'starred' : ''">★</i>
                        <i @click.stop="rateGame(game.title, 5)" :class="game.game_rating > 4 ? 'starred' : ''">★</i>
                      </span>
                    </div>
                </div>

                <hr />

                <div class="my-2">
                  <font-awesome-icon @click.stop.prevent="addGameInterest('wishlist', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-gift" />
                  <font-awesome-icon @click.stop.prevent="addGameInterest('currently playing', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-gamepad" />
                  <font-awesome-icon @click.stop.prevent="addGameInterest('played', game.title)" class="text-white px-1 hover:text-green-300 cursor-pointer" icon="fa-solid fa-hourglass-half" />
                  <font-awesome-icon @click.stop.prevent="addGameInterest('completed', game.title)" class="text-white px-1 text-primary" icon="fa-solid fa-check-to-slot" />
                </div>
            </div>
          </div>
          <div v-else class="text-xs px-3 font-bold text-white text-left">
            You haven't completed any games yet. What are you waiting for? They aren't going to complete themselves.
            <button @click="navigateToGameSearchPage()" 
                    type="button" 
                    class='add-more-games-btn mx-2 sm:mt-0 mt-2 py-1 px-2 inline-flex items-center text-sm font-medium rounded-r-lg border border-transparent text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none'>
              +
            </button>
          </div>
          <div v-if="completedGames.length > 0" class="text-center pt-3 px-3">
            Don't forget to <span class="font-extrabold">RATE</span> the games you complete - it helps us generate AI-based game <a class="text-primary decoration-2 hover:underline font-medium cursor-pointer" @click="navigateToRecommendationsPage">Recommendations</a> for you and also reward you with <span class="text-yellow-400 font-bold">GameKarma</span> points.
          </div>
          <hr class="my-8">
        </div>

      </div>

      <!-- POP-UP MODAL FOR GAME PROPERTIES (STARTS) -->
      <div id="hs-gameprops-modal-allgames" class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto flex justify-center items-center inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm">
        <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-3">
          <div class="flex flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
            <div class="flex justify-between items-center py-3 px-4 border-b border-gray-700">
              <h3 class="font-bold text-white">
                Game Properties
              </h3>
              <button type="button" class="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm focus:ring-gray-700 focus:ring-offset-gray-800" data-hs-overlay="#hs-gameprops-modal-allgames">
                <span class="sr-only">Close</span>
                <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                </svg>
              </button>
            </div>
            <div v-if="gameSelected && gameSelected.game_attributes"  class="p-4 overflow-y-auto">
              <div class="mt-1 text-gray-400" v-for="(attribute, index) in gameSelected.game_attributes" :key="index">
                {{ attribute }}
              </div>
            </div>
            <div v-else>
              <div class="mt-1 text-gray-400">
                No game properties available.
              </div>
            </div>
            <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-gray-700">
              <button type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-all text-sm bg-slate-900 hover:bg-slate-800 border-gray-700 text-gray-400 hover:text-white focus:ring-offset-gray-800" data-hs-overlay="#hs-gameprops-modal-allgames">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- POP-UP MODAL FOR GAME PROPERTIES (ENDS) -->

      <FooterComponent />
    </div>
  </body>
</template>



<script setup>
  import axios from 'axios';
  // import Cookies from 'js-cookie';

  import { computed, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';

  import HeaderComponent from '@/components/Layout/HeaderComponent.vue';
  import FooterComponent from '@/components/Layout/FooterComponent.vue';

  // Axios configurations
  // axios.defaults.xsrfCookieName = 'csrftoken';
  // axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  axios.defaults.withCredentials = true;


  // const csrfToken = Cookies.get('csrftoken');
  const store = useStore();
  const loading = ref(true);

  const router = useRouter();

  const isLargeScreen = ref(window.innerWidth > 450);

  const loadingImagePlaceholderPath = ref('images/loading-image-placeholder.png')
  const initialLoad = ref(true);

  const userId = ref(null);
  const gameSelected = ref(null);

  const allGamesFullList = ref([]);
  const gameSelectedTitle = ref(null);
  const wishlistGames = ref([]);
  const currentlyPlayingGames = ref([]);
  const playedGames = ref([]);
  const completedGames = ref([]);
  // const newGameRating = ref([]);

  const sectionShowing = ref('All');


  const navigateToLoginPage = () => {
      router.push('/login');
  }

  const navigateToRecommendationsPage = () => {
    router.push('/recommendations')
  }

  const navigateToGamePickerPage = () => {
    router.push('/')
  }

  const navigateToGameSearchPage = () => {
    router.push('/all-games')
  }

  const navigateToIgnUrl = (game) => {
    const ignUrl = game.ign_url;

    if (ignUrl) {
      window.open(ignUrl, '_blank');
    } else {
      window.open('https://www.ign.com/', '_blank');
    }
  }

  const navigateToMetacriticUrl = (game) => {
    const searcheableTitle = makeGameNameSearchable(game.title);
    const metacriticUrl = `https://www.metacritic.com/search/${searcheableTitle}/`

    // Browse and open a new window to the metacritic url
    window.open(metacriticUrl, '_blank');
  }

  const navigateToHowLongToBeatUrl = (game) => {
    const searcheableTitle = makeGameNameSearchable(game.title);
    const howLongToBeatUrl = `https://howlongtobeat.com/?q=${searcheableTitle}`

    // Browse and open a new window to the metacritic url
    window.open(howLongToBeatUrl, '_blank');
  }
  
  const makeGameNameSearchable = (gameName) => {
    if (!gameName) {
      return '';
    }

    const elements = [
        "™",
        "®",
        "(Game Preview)",
        "(Xbox Series X|S & PC)",
        "(Xbox Series X|S)",
        "(Xbox One Edition)",
        "(Xbox One)",
        "Game Preview",
        "Xbox Series X|S & PC",
        "Xbox Series X|S",
        "Xbox One Edition",
        "Xbox One"
    ];

    let result = gameName;
    for (let element of elements) {
        const regex = new RegExp(escapeRegExp(element), 'g');
        result = result.replace(regex, "");
    }
    return result.trim();
  }

  const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  const selectGame = (game) => {
    gameSelected.value = game;
  }

  const isRecentlyReleased = (game) => {
    if (!game.release_on_gamepass_date) return false;
    const releaseDate = new Date(game.release_on_gamepass_date);
    const today = new Date();
    const timeDifference = today - releaseDate;
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    return daysDifference <= 7;
  }

  const depleteQuota = async (attemptType) => {
    const inputData = {
      attempt_type: attemptType
    };

    // Make the API call
    try {
      await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/quota-deplete/`, inputData,
        {
          headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken
          },
          withCredentials: true,
        })

    } catch (error) {
      console.error('Could not deplete the quota for the user.', error);
    }
  }

  const addGameInterest = async (interestStatus, gameTitle) => {

      let gameTitleFinal;

      if (gameTitle) {
        gameTitleFinal = gameTitle;
      } else {
        gameTitleFinal = gameSelectedTitle.value;
      }

      // Define the payload data
      const inputData = {
        game_title: gameTitleFinal,
        status: interestStatus
      };

      // Make the API call
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/game-interest/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })
      } catch (error) {
        console.error('There was an error!', error);
      }

      let gameData = null

      // Looking to see which list the game is currently in
      const gameIndexWishlist = wishlistGames.value.findIndex(
        game => game.title === gameTitleFinal
      );

      const gameIndexCurrPlaying = currentlyPlayingGames.value.findIndex(
        game => game.title === gameTitleFinal
      );

      const gameIndexPlayed = playedGames.value.findIndex(
        game => game.title === gameTitleFinal
      );

      const gameIndexCompleted = completedGames.value.findIndex(
        game => game.title === gameTitleFinal
      );

      // If the game is found we remove it from the list, and store 
      // the game properties in a variable to be added to the new list
      if (gameIndexWishlist > -1) {
        gameData = wishlistGames.value[gameIndexWishlist];
        wishlistGames.value.splice(gameIndexWishlist, 1);
      }

      if (gameIndexCurrPlaying > -1) {
        gameData = currentlyPlayingGames.value[gameIndexCurrPlaying];
        currentlyPlayingGames.value.splice(gameIndexCurrPlaying, 1);
      }

      if (gameIndexPlayed > -1) {
        gameData = playedGames.value[gameIndexPlayed];
        playedGames.value.splice(gameIndexPlayed, 1);
      }

      if (gameIndexCompleted > -1) {
        gameData = completedGames.value[gameIndexCompleted];
        completedGames.value.splice(gameIndexCompleted, 1);
      }

      // Add the game to the new list and maintain sorted order, unless the
      // game is dropped, in that case we don't add it back
      if (interestStatus === 'wishlist') {
        wishlistGames.value.push(gameData);
        wishlistGames.value.sort((a, b) => (a.title > b.title) ? 1 : -1);
      }
      else if (interestStatus === 'currently playing') {
        currentlyPlayingGames.value.push(gameData);
        currentlyPlayingGames.value.sort((a, b) => (a.title > b.title) ? 1 : -1);
      }
      else if (interestStatus === 'played') {
        playedGames.value.push(gameData);
        playedGames.value.sort((a, b) => (a.title > b.title) ? 1 : -1);
      }
      else if (interestStatus === 'completed') {
        completedGames.value.push(gameData);
        completedGames.value.sort((a, b) => (a.title > b.title) ? 1 : -1);
      }
      else if (interestStatus === 'dropped') {
        // Do nothing as the game has been dropped
      }

      // Deplete the user's quota
      await depleteQuota('game_addition_quota');
  }


  const rateGame = async (gameTitle, newRating) => {

      if (newRating < 0 || newRating > 5 || isNaN(newRating)) {
        alert('Please enter a rating between 0 and 5.');
        return;
      }

      // Define the payload data
      const inputData = {
        game_title: gameTitle,
        rating: newRating
      };

      // Make the API call
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/rate-game/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

        if (response.data) {
          await store.dispatch('setGameKarmaPoints', response.data.total_points_accumulated);
        }
      } catch (error) {
        console.error('There was an error!', error);
      }

      // Manually update just the game that was rated
      const gameIndexCurrPlaying = currentlyPlayingGames.value.findIndex(
        game => game.title === gameTitle
      );

      const gameIndexPlayed = playedGames.value.findIndex(
        game => game.title === gameTitle
      );

      const gameIndexCompleted = completedGames.value.findIndex(
        game => game.title === gameTitle
      );

      // If the game is found we update its value
      if (gameIndexCurrPlaying > -1) {
        currentlyPlayingGames.value[gameIndexCurrPlaying].game_rating = newRating;
      }

      if (gameIndexPlayed > -1) {
        playedGames.value[gameIndexPlayed].game_rating = newRating;
      }

      if (gameIndexCompleted > -1) {
        completedGames.value[gameIndexCompleted].game_rating = newRating;
      }

      // Deplete the user's quota
      await depleteQuota('game_ratings_quota');
  }

  const updateGames = async () => {

      loading.value = true;

      wishlistGames.value = [];
      currentlyPlayingGames.value = [];
      playedGames.value = [];
      completedGames.value = [];

      // Retrieving user's wishlist, currently playing, and completed games
      let userGameInterests = null;
      try {
        userGameInterests = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-game-interests`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'  // Necessary for passing of login cookies from backend
        })
      } catch (e) {
        loading.value = false;
        await store.dispatch('setAuth', false);
        await navigateToLoginPage();
      }

      const userGameInterestsInterim = await userGameInterests.json();
      let allUserGameInterests = [];

      // Filter out the games that are not in the full list, and add all their properties
      for (let i = 0; i < userGameInterestsInterim.length; i++) {
        const gameIndex = allGamesFullList.value.findIndex(
          game => game.title === userGameInterestsInterim[i].game_title
        );

        if (gameIndex > -1) {
          allGamesFullList.value[gameIndex].game_status = userGameInterestsInterim[i].game_status;
          allGamesFullList.value[gameIndex].game_rating = userGameInterestsInterim[i].game_rating;
          allGamesFullList.value[gameIndex].game_image = userGameInterestsInterim[i].game_image;
          allGamesFullList.value[gameIndex].is_on_gamepass = userGameInterestsInterim[i].is_on_gamepass;
          allUserGameInterests.push(allGamesFullList.value[gameIndex]);
        }
      }

      const interimWishlistGames = [];
      const interimCurrentlyPlayingGames = [];
      const interimPlayedGames = [];
      const interimCompletedGames = [];

      // Add games to their respective arrays
      for (let i = 0; i < allUserGameInterests.length; i++) {
        if (allUserGameInterests[i].game_status === 'wishlist') {
          interimWishlistGames.push(allUserGameInterests[i]);
        }

        if (allUserGameInterests[i].game_status === 'currently playing') {
          interimCurrentlyPlayingGames.push(allUserGameInterests[i]);
        }

        if (allUserGameInterests[i].game_status === 'played') {
          interimPlayedGames.push(allUserGameInterests[i]);
        }

        if (allUserGameInterests[i].game_status === 'completed') {
          interimCompletedGames.push(allUserGameInterests[i]);
        }
      }

      // Sort the games by their game title in alphabetical order before adding them to the lists
      if (interimWishlistGames.length > 0) {
        interimWishlistGames.sort((a, b) => (a.title > b.title) ? 1 : -1);
        wishlistGames.value = interimWishlistGames;
      }

      if (interimCurrentlyPlayingGames.length > 0) {
        interimCurrentlyPlayingGames.sort((a, b) => (a.title > b.title) ? 1 : -1);
        currentlyPlayingGames.value = interimCurrentlyPlayingGames;
      }

      if (interimPlayedGames.length > 0) {
        interimPlayedGames.sort((a, b) => (a.title > b.title) ? 1 : -1);
        playedGames.value = interimPlayedGames;
      }

      if (interimCompletedGames.length > 0) {
        interimCompletedGames.sort((a, b) => (a.title > b.title) ? 1 : -1);
        completedGames.value = interimCompletedGames;
      }

      loading.value = false;
      initialLoad.value = true;

  }

  const generateGameDetailSlug = (gameTitle) => {
    return gameTitle
      .toLowerCase()                       // Convert to lowercase
      .replace(/[^a-z0-9\s-]/g, '')        // Remove invalid characters
      .trim()                              // Remove leading/trailing spaces
      .replace(/\s+/g, '-')                // Replace spaces with hyphens
      .replace(/-+/g, '-')                 // Remove duplicate hyphens
  }

  const viewGameDetailsPage = async (gameId, gameTitle, event) => {

    // Check if the click target is the inner link or inside it
    if (event.target.closest('a[data-hs-overlay="#hs-gameprops-modal-allgames"]')) {
      return; // Exit if the click originated from the inner link
    }

    const gameDetailSlug = await generateGameDetailSlug(gameTitle);
    router.push(
      { 
        name: 'GameDetailsPage', 
        params: { gameId: gameId, gameTitle: gameDetailSlug } 
      }
    );
  }


  onMounted(async () => {

    // Retrieving the user
    try {
      const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'  // Necessary for passing of login cookies from backend
      })

      // Check for 403 error
      if (response.status === 403) {
        await store.dispatch('setAuth', false);
        await navigateToLoginPage();
      }

      const content = await response.json();

      if (content.detail === 'Unauthenticated!') {
        await store.dispatch('setAuth', false);
        await navigateToLoginPage();
      } else {
        await store.dispatch('setAuth', true);
        userId.value = content.id;
      }
    } catch (e) {
      await store.dispatch('setAuth', false);
      await navigateToLoginPage();
    }

    // Retrieving all games so we can get more properties from them later
    const allGamesRes = require('../assets/data/games-info-all.json');
    allGamesFullList.value = allGamesRes['data'];

    // Add backup URLs for the game videos if they are not available
    allGamesFullList.value = allGamesFullList.value.map(game => {
      if (!game.youtube_review_url) {
        game.youtube_review_url = `https://www.youtube.com/results?search_query=${game.title}+review`;
      }

      if (!game.youtube_trailer_url) {
        game.youtube_trailer_url = `https://www.youtube.com/results?search_query=${game.title}+trailer`;
      }

      if (!game.youtube_walkthrough_url) {
        game.youtube_walkthrough_url = `https://www.youtube.com/results?search_query=${game.title}+full+walkthrough`;
      }

      if (!game.microsoft_store_url) {
        game.microsoft_store_url = `https://www.xbox.com/en-us/games/store/${game.title}/${game.xbox_api_id}`;
      }

      // Computing metacritic score color class
      let metacriticScore = game.metacritic_score;

      if (metacriticScore === null) {
        game.metacritic_score = 'tbd';
        game.metacritic_score_color_class = 'score-gray';
      } else if (metacriticScore <= 49) {
        game.metacritic_score_color_class = 'score-red';
      } else if (metacriticScore <= 74) {
        game.metacritic_score_color_class = 'score-yellow';
      } else if (metacriticScore <= 100) {
        game.metacritic_score_color_class = 'score-green';
      }

      return game;
    });

    await updateGames();
  })

  const authenticated = computed(() => store.state.authenticated);

</script>

<style scoped>

.add-more-games-btn {
  border-radius: 0.375rem;
  border: 1px solid #42b983;
  color: white;
}

.add-more-games-btn:hover {
    background-color: #42b983; 
}

.game-list-btn-selector {
    border-top: 2px solid #42b983; 
    border-bottom: 2px solid #42b983; 
    border-left: 1px solid #42b983; 
    border-right: 1px solid #42b983; 
    color: inherit; 
}

.game-list-btn-selector-middle {
    border-left: 1px solid #42b983; 
    border-right: 1px solid #42b983;
    color: inherit; 
}

.game-list-btn-selector-left {
    border-left: 2px solid #42b983; 
    color: inherit; 
}

.game-list-btn-selector-right {
    border-right: 2px solid #42b983; 
    color: inherit; 
}

.game-list-btn-selector:hover {
    background-color: #42b983; 
}

.game-list-btn-selector:focus {
    background-color: #42b983; 
    outline: none; 
}

.ign-score-container {
  position: relative;
  text-align: center;
  color: white;
}

.ign-score {
  position: absolute;
  font-weight: bolder;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
}

/* These styles are applied by default.
   They will also override the hover
   style for all stars to the right
   of the currently hovered star. */
.rate i, .rate i:hover ~ i {
  color: gray;
  text-shadow: none;
  transition: color 200ms,
              text-shadow 200ms;
  /* This will remove the delay when
     moving the cursor left or right
     within the set of stars. */
  transition-delay: 0;
}

/* This is the style that will be
   applied to all stars and then
   subsequently removed from the stars
   to the right of the one being
   hovered. */
.rate:hover i {
  color: #fc0;
  text-shadow: #fc0 0 0 20px;
}

/* Make the effect apply one star at a
   time. Limiting the selector to when
   .rate is hovered removes the effect
   without delay when cursor leaves
   the .rate area. */
.rate:hover i:nth-child(2) {
  transition-delay: 30ms;
}

.rate:hover i:nth-child(3) {
  transition-delay: 60ms;
}

.rate:hover i:nth-child(4) {
  transition-delay: 90ms;
}

.rate:hover i:nth-child(5) {
  transition-delay: 120ms;
}

/* Miscellaneous styles. */
.rate i {
  cursor: pointer;
  font-style: normal;
}

.score-box {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4.5px; /* Reduced proportionally from 5.25px */
  font-size: 12px; /* Reduced proportionally from 14px */
  font-weight: bold;
  color: #fff;
}

.score-gray {
  background-color: #6B7280;
}

.score-red {
  background-color: #FE1016;
}

.score-yellow {
  background-color: #FFC541;
}

.score-green {
  background-color: #5DC53E;
}

.starred {
  color: #fc0 !important;
  text-shadow: #fc0 0 0 20px !important;
}


</style>
